.navbar {
  &-outer {
    max-width: 100vw;
  }
  @media(max-width: 767px){
    //justify-content: center;

  }
  @media(max-width: 963px){
    padding-right: 2em;
    padding-left: 2em;
  } 
  .header{
    @media(max-width: 767px ){display: none} 
  }
  p, .link {
    font-size: 14px;
  }
  min-height: 60px;
  @media(min-width: 767px){min-height: 80px;}
    font-size: 18px;
    .svg-icon {
       width: 20px;
       height: 20px;
       position: relative;
       bottom: 3px;
       stroke-width: 0.5;
       @media(max-width: 500px) {
         right: -10px;
       }
    }
    &.scrolled {
      transition: all 0.25s;
      background: #faf3ec;
      color: black;
      border: solid thin black;
      .svg-icon{
        fill: black;
        stroke: black;
      }
    }
    .nav-links {
      @media(max-width: 767px){
      justify-content: space-between;
      width: 100%;
      }
      .link {
        min-width: 70px;
        @media(min-width: 768px){
          margin-right: 2em;
        }
        &.active, &.open, &:hover {
          letter-spacing: 1.8px;
          font-weight: 600;
        }
    
        
      }
      .color-pickers {
        position: absolute;
        right: -5px;
        cursor: default;
        @media(max-width: 500px){
          right: -15px;
        }
        opacity: 0;
        transition: all 0.3s;
        border: thin solid black;
        //max-height: 0px;
        &:after, &:before {
          bottom: 100%;
          right: 10px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        
        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #faf3ec;
          border-width: 5px;
          margin-left: -5px;
        }
        &:before {
          border-color: rgba(0, 0, 0, 0);
          border-bottom-color: #000;
          border-width: 6px;
          margin-left: -6px;
          margin-right: -1px;
        }
        .color-picker {
          p {
            color: black;
          }
          input[type=color]{
            width: 15px;
            height: 15px;
            border: none;
            border-radius: 40px;
            background: none;
          }
          input[type="color"]::-webkit-color-swatch-wrapper {
            padding: 0;
          }
          input[type="color"]::-webkit-color-swatch {
            border: solid 1px #000; /*change color of the swatch border here*/
            border-radius: 40px;
          }
        }
      }
      
      .nav-color.open {
        .color-pickers {
          //max-height: 500px;
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }
}