.Contact {
  .copy-container {
    h1 {
      @media (max-width: 767px){
        font-size: 1.5em;
      }
    }
  }
  .email-copied{
    transition: opacity 0.3s;
    right: -320%;
    bottom: 35%;
    @media(max-width: 500px) {
      right: 20%;
    }
    @media(max-width: 767px) {
      border: thin solid black;
      padding: 1em;
    }
  }
  .email-links {
    transition: opacity 0.5s;
    list-style-type: none;
    @media(max-width: 500px){
      position: absolute;
      right: 0;
      width: 50%;
      top: 75px;
      flex-wrap: wrap;
      padding-left: 1em;
    }
    li {
      img {
        max-width: 30px;
        max-height: 30px;
        object-fit: contain;
      }
    @media(max-width: 767px){
      width: 22%;
      padding: 0.5em 1em;
      img {
        max-width: 20px;
        max-height: 20px;
        object-fit: contain;
      }
    }
      position: relative;
      
      &:hover {
        // p {
        //   opacity: 1;
        //   transition: all 0.3s;
        // }
      }
   
      a {
        transition: all 0.3s;
        p {
          opacity: 0;
          font-size: 12px;
        }
      }
    }
  }
  #links-area{
    .icon {
      width: 30px;
    }
  }

}