.about {
  margin-left: 2rem;
  @media(min-width: 864px){
    margin-left: 4remem;
  }
  margin-top: 80px;

  @media (min-width: 864px){
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    //margin-left: 0;
  }

  .js-next-section {
    cursor: pointer;
    position: relative;
    
    //animation-delay: 2s;
    
    font-size: 25px;
    //bottom: 0.25em;
    opacity: 0;
    transition-property: opacity;
  transition-duration: 5s;
  transition-delay: 2s;
    // transition-delay: 2s;
  }
  .bio-container {
    &:focus{
      outline: none;
    }
    //overflow:hidden;
    .bio-section {
     // justify-content: flex-start;
      @media(min-width: 864px){
        justify-content: center;
      }
      //height: fit-content;
      .title-word {
        //margin-bottom: 0.25em;
        opacity: 0;
        position: relative;
        transition: opacity 0.5s;
        font-weight: 600;
      
      }
      // &.init {
      //   opacity: 1;
      //   .title-word {
      //     opacity: 1;
      //   }
      // }
      &.is-selected {
        .title-word {
          @media(max-width: 863px){
            flex-direction: column;
            align-items: flex-start;
          }
          animation: fadeInAndUp 1s;
          animation-fill-mode: forwards;
          &-1 {
            animation-delay: 0.3s
          }
          &-2 {
            animation-delay: 0.8s
          }
          &-3 {
            animation-delay: 1.2s
          }
          &-4 {
            animation-delay: 1s
          }
        }
        .js-next-section {
          animation: fadeIn 1s ease-in-out 2s, pulseMove 1s ease-in-out 3s infinite;
          animation-fill-mode: forwards;
          animation-direction: alternate;
          right: -20px;
          @media(max-width: 863px){
            padding-top: 1em;
            right: 0px;
          }
          &.three{
            //animation-delay: 1.2s;
          }
          &.four {
            //animation-delay: 1.5s;
          }
        }
        
      }
    }
    .flickity-viewport {
      width: 100%;
      max-height: 300px;
      overflow: hidden;
      @media(min-width: 864px){
        max-height: 500px;
        overflow: visible;
      }
    }
    .flickity-slider {
      height: 100%;
    }
    .flickity-prev-next-button {
      //display: none;
    }
  }
    

      .blurb-word {
        margin-bottom: 2em;
        @media(min-width: 864px){margin-bottom: 0;}
        opacity: 0;
        position: relative;
        animation: fadeInAndUp 1s;
        animation-fill-mode: forwards;
        &-1{ animation-delay: 1s }
        &-2 { animation-delay: 1.2s }
        &-3 { animation-delay: 1.4s }
        &-4 { 
          cursor: pointer;
          animation-delay: 1.8s 
        }
      }

  @keyframes fadeInAndUp {
    0% {opacity: 0; top: 20px;}
    100% {opacity: 1; top: 0}
  }

  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 0.7;}
  }


  @media(max-width: 863px) {
    @keyframes pulseMove {
      0% { 
        opacity: 0.7;
        right: 0px;
      }
      100% {
        opacity: 1;
        right: -5px
      }
    }
  }
  @media(min-width: 864px) {
    @keyframes pulseMove {
      0% { 
        opacity: 0.7;
        right: -20px;
      }
      100% {
        opacity: 1;
        right: -25px
      }
    }
  }
}