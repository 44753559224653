$break-iphone5: 20em; // 320px
$break-iphoneXx: 23.44em; // 375px
$break-iphoneXy: 50.75em; // 812px
$break-xs: 26.25em; // 420px
$break-5: 500px;
$break-s: 37.5em; // 600px
$break-ms: 41em; // 656px
$break-mob: 48em; // Mobile - 768px
$break-m: 54em; // 864px
$break-l: 60.25em; // 964px
$break-xl: 78em; // 1248px
$break-xxl: 100em; // 1600px

// Special cases
$break-nav: 1180px;
$break-ipad-pro: 64em; // 1024px


$grid-medium: 750px;
$grid-large: 990px;
$grid-widescreen: 1400px;
$grid-gutter: 30px;

$small: 'small';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$widescreen: 'widescreen';

$breakpoints: (
  $small '(max-width: #{$grid-medium - 1})',
  $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
  $medium-down '(max-width: #{$grid-large - 1})',
  $medium-up '(min-width: #{$grid-medium})',
  $large '(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})',
  $large-down '(max-width: #{$grid-widescreen - 1})',
  $large-up '(min-width: #{$grid-large})',
  $widescreen '(min-width: #{$grid-widescreen})'
);


/*============================================================================
  Generate breakpoint-specific column widths and push classes
    - Default column widths: $breakpoint-has-widths: ($small, $medium-up);
    - Default is no push classes
    - Will not work if `styles/global/grid.scss` is removed
==============================================================================*/
$breakpoint-has-widths: ($small, $medium-up);
$breakpoint-has-push: ();


