@import url('https://fonts.googleapis.com/css?family=Robotos&display=swap');
@import './sass/lib/_align.scss';
@import './sass/lib/_borders.scss';
@import './sass/lib/_breakpoints.scss';
@import './sass/lib/_containers.scss';
@import './sass/lib/_display.scss';
@import './sass/lib/_positioning.scss';
@import './sass/lib/_spacing.scss';
@import './sass/lib/_typography.scss';
@import './sass/lib/_colors.scss';
body {
  //background: rgb(13, 3, 3);
  //background: #b9c2c7;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[style~="z-index: 10000"] {
  display: none;
}
