* {
  font-family: "Roboto", sans-serif;
  //letter-spacing: 1.2px;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.ls05{
  letter-spacing: 0.5px;
}

.ls1{
  letter-spacing: 1px;
}

.ls2{
  letter-spacing: 2px;
}

.lh1 {
  line-height: 1;
}

.lh15 {
  line-height: 1.5;
}

.lh2 {
  line-height: 2;
}

.fw3{
  font-weight: 300;
}

.fw4{
  font-weight: 400;
}

.fw5{
  font-weight: 500;
}

.fw6{
  font-weight: 600;
}

.fwb{
  font-weight: bold;
}

.caps {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.underline {
  border-bottom: solid 1px currentColor;
  -webkit-width: fit-content;
  width: fit-content;
  &-2{
    border-bottom: solid 2px currentColor;
    -webkit-width: fit-content;
    width: fit-content;
  }
}

 p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
  letter-spacing: 1.2px;
}

.nowrap {
  white-space: nowrap;
}


.small {
  font-size: 14px;
  &-xs {
    font-size: 12px;
  }
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  // fill: #4691f6;
}

.svg-icon circle {
  // stroke: #4691f6;
  stroke-width: 1;
}