.projects-outer {
//  min-height: 800px;
.projects-content {
  .page-title {
    h1 {
      @media (max-width: 767px){
        font-size: 1.5em;
        margin-bottom: 0;
      }
    }
  }
}
 .projects {
   @media (min-width: 768px){
     flex-direction:row
    }
    .project-item {
      width: 100%;
      @media(min-width: 500px){width: 48%;}
      @media(min-width: 768px){width: 31%;}
      transition: all 0.25s;
      top: 0;
    opacity: 0.8;
      &:hover {
       transition: all 0.25s;
        top: -5px;
        opacity: 1;
      }
      img {
        // width: 250px;
        // height:120px;
        @media(min-width:500px){height: 200px;}
        @media(min-width:768px){height: 250px;}
        object-fit: cover;
      }
    }

 }

}