.bggrn {
  background-color: #4d6376;
}

.bggry {
  background-color: #faf3ec
}

.bcw { 
  background-color: white;
}