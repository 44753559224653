.rel { position: relative }
.abs { position: absolute }
.fix { position: fixed }
.fill, .top { top: 0 }
.fill, .bottom { bottom: 0 }
.fill, .left { left: 0 }
.fill, .right { right: 0 }
.x { width: 100% }
.x2 { width: 50% }
.fit-content {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.xa {width: auto}
.y { height: 100% }
.y2 { height: 50% }
.ya {height: auto}


.z1 {z-index: 100}
.z2 {z-index: 200}
.z3 {z-index: 300}
.z4 {z-index: 400}
.z5 {z-index: 500}
.z6 {z-index: 600}
.z7 {z-index: 700}
.z8 {z-index: 800}
.z9 {z-index: 900}
