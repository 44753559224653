
.transparent {opacity: 0;transition: 0.5s}
.hidden, .hide {display: none !important}

.f { display: flex }
.fw { flex-wrap: wrap }
.fwn {flex-wrap: nowrap }
.ais { align-items: flex-start }
.aie { align-items: flex-end }
.aic { align-items: center }
.aib { align-items: baseline }
.jcs { justify-content: flex-start }
.jce { justify-content: flex-end }
.jcc { justify-content: center }
.jcb { justify-content: space-between }
.jca { justify-content: space-around }
.jceven { justify-content: space-evenly }
.fdc { flex-direction: column }
.fdr { flex-direction: row }
.fa {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.mobile-hidden {
  @media(max-width: 767px) {
    display: none;
  }
}
.desktop-hidden {
  @media(min-width: 768px){
    display: none;
  }
}

.pointer {
  cursor: pointer;
}



