

.App {
   //background: linear-gradient(black , black);
  .about-outer {
    min-height: 500px;
    @media (min-width: 864px){min-height: 70vh;}
    
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-subheader {
    animation: ComingSoon infinite 1.85s linear;
    animation-direction: alternate;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  //color: #fcf0e4;
  //color: black;
}
.particle-outer > div {
  height: 100%;
}



@keyframes ComingSoon {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
